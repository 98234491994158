<template>
  <div class="templateClass">
    <div class="templateCentreContent">
      <el-card class="box-card">
        <div slot="header" style="position: relative">
          <div class="query">
            <el-input
              class="input-field"
              style="width: 250px"
              clearable
              placeholder="请输入昵称/手机号/邮箱"
              v-model="queryData.keyWord"
              @keyup.enter.native="getDataList(true)"
            ></el-input>

            <el-button type="success" class="ml10" @click="getDataList(true)">查询</el-button>

            <el-button class="op_btn" type="primary" @click="addOrEditPopup()">新增用户</el-button>
          </div>
        </div>

        <!-- PC端展示 -->
        <el-table
          class="pc-table"
          :data="listData"
          v-loading="loading"
          element-loading-text="正在拼命加载中"
          element-loading-spinner="el-icon-loading"
          border
          stripe
        >
          <el-table-column type="index" :index="1" label="#" width="50"></el-table-column>
          <el-table-column prop="nickName" label="用户昵称" min-width="120"></el-table-column>
          <el-table-column prop="userName" label="姓名" min-width="120"></el-table-column>
          <el-table-column prop="mobile" label="手机号" min-width="120"></el-table-column>
          <el-table-column prop="email" label="邮箱" min-width="200"></el-table-column>
          <el-table-column prop="qrCode" label="二维码" min-width="120">
            <template slot-scope="scope">
              <el-button type="text" @click="getUserQrCode(scope.row.id, scope.row.userName)">查看二维码</el-button>
            </template>
          </el-table-column>
          <el-table-column prop="type" label="类型" min-width="100">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.type == 1">普通用户</el-tag>
              <el-tag v-else-if="scope.row.type == 2">医疗用户</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="pushType" label="报告推送类型" min-width="140">
            <template slot-scope="scope">
              <el-tag
                v-for="(item, index) in pushTypeList"
                :key="index"
                v-if="item.value == scope.row.pushType"
              >{{ item.label }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="status" label="状态" min-width="50">
            <template slot-scope="scope">
              <span :style="scope.row.status == 1 ? 'color:#67C23A' : 'color:#F56C6C'">
                {{ scope.row.status == 1 ? "启用" : "禁用" }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="创建时间" min-width="160">
            <template slot-scope="scope">
              <p>{{ scope.row.createTime | dateFormat1 }}</p>
            </template>
          </el-table-column>
          <el-table-column fixed="right" prop="operation" label="操作" width="320px;">
            <template slot-scope="scope">
              <div class="tableOperation">
                <el-button type="text" @click="editCoverPopup(scope.row.id, scope.row.cover)">上传封面</el-button>
                <!-- <span class="operationLine">|</span> -->
                <!-- <el-upload
                  class="avatar-uploader"
                  style="display: inline-block"
                  :action="`${$store.state.baseUrl}user-login/updateLogo/${scope.row.id}`"
                  :show-file-list="false"
                  :on-success="uploadSuccess"
                  :on-error="$uploadError"
                  :before-upload="$beforeUploadImage"
                  accept="image/*"
                >
                  <el-button type="text">上传logo</el-button>
                </el-upload> -->
                <span class="operationLine">|</span>
                <el-button type="text" @click="addOrEditPopup(scope.row.id)">编辑</el-button>
                <span class="operationLine">|</span>
                <el-button class="F56C6C" type="text" @click="del(scope.row.id)">删除</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>

        <!-- 移动端展示  @scroll="handleScroll"-->
        <div class="mobile-cards">
          <el-card
            v-for="(item, index) in listData"
            :key="index"
            class="user-card"
            shadow="hover"
          >
            <div class="card-content">
              <div class="card-header">
                 <!-- <el-dropdown>
                  <el-button type="text" class="el-dropdown-link">
                    操作<i class="el-icon-arrow-down el-icon--right"></i>
                  </el-button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item @click.native="editCoverPopup(item.id, item.cover)">上传封面</el-dropdown-item>
                    <el-dropdown-item @click.native="addOrEditPopup(item.id)">编辑</el-dropdown-item>
                    <el-dropdown-item @click.native="del(item.id)">删除</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown> -->
              </div>
              <div class="card-item">
                <div class="flex align_center pb10 mb10 br_bottom pl10 pr10">
                  <p class="item_seq">{{ getSeq(index, queryData.current, queryData.size) }}</p>
                  <p class="ml10 mr10">{{ item.userName }}</p>
                  <p  class=" flex1 t_r" ><el-tag v-if="item.type == 1">普通用户</el-tag>
                    <el-tag v-else-if="item.type == 2">医疗用户</el-tag></p>
                </div>
                <div class="pl10 pr10 pb10 mb10">
                  <p class="mb10">手机号: {{ item.mobile }}</p>
                  <p class="mb10">邮箱: {{ item.email }}</p>
                  <p class="mb10">状态: <span :style="item.status == 1 ? 'color:#67C23A' : 'color:#F56C6C'">{{ item.status == 1 ? "启用" : "禁用" }}</span></p>
                  <p class="mb10">创建时间: {{ item.createTime | dateFormat1 }}</p>
                </div>
              </div>
            </div>
          </el-card>
          <div v-if="loading" class="loading-indicator">加载中...</div>
        </div>

        <el-pagination
          class="floatRight"
          v-if="total > 10"
          @size-change="getDataListSizeChange"
          @current-change="getDataListCurrentChange"
          :current-page.sync="queryData.current"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="queryData.size"
          :total="total"
          layout="total,sizes, prev, pager, next"
        ></el-pagination>
      </el-card>
    </div>
    <add-or-edit ref="addOrEdit" @refreshDataList="getDataList()"></add-or-edit>
    <see-pig-img ref="seePigImg"></see-pig-img>

    <el-dialog
      title="修改"
      :close-on-click-modal="false"
      :visible.sync="visible"
      center
      width="800px"
    >
      <el-form ref="dataForm" label-width="110px">
        <el-form-item label="封面图片" prop="cover">
          <img
            style="display: block; max-width: 50%"
            v-if="coverUrl"
            :src="coverUrl"
          />
          <el-upload
            class="avatar-uploader"
            style="display: inline-block"
            :action="`${$store.state.baseUrl}user-login/updateCover/${this.id}`"
            :show-file-list="false"
            :on-success="uploadSuccess"
            :on-error="$uploadError"
            :before-upload="$beforeUploadImage"
            accept="image/*"
          >
            <el-button type="primary">{{
              coverUrl ? "重新上传" : "点击上传"
            }}</el-button>
          </el-upload>
          <div class="formPs">
            <span>尺寸要求：1191x 1664</span>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="visible = false">关 闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import AddOrEdit from "./loginUser-add-or-edit";
import seePigImg from "@/components/seePigImg";
export default {
  components: {
    AddOrEdit,
    seePigImg,
  },
  data() {
    return {
      loading: false,
      listData: [],
      total: 0,
      queryData: {
        size: 10,
        current: 1,
      },
      visible: false,
      coverUrl: "",
      id: "",
      pushTypeList: [
        {
          label: "曲阜医院",
          value: 1,
        },
        {
          label: "第一健康",
          value: 2,
        },
      ],
    };
  },
  created() {
    this.getDataList();
  },
  methods: {
    editCoverPopup(id, url) {
      this.visible = true;
      this.coverUrl = url;
      this.id = id;
    },

    getUserQrCode(userId, name) {
      this.$http.post(`user-login/getUserQrCode/${userId}`).then((res) => {
        if (res.data.code == 200) {
          let data = res.data.data;
          this.seePigImg(data, name);
        }
      });
    },
    /**
     * 查看大图
     */
    seePigImg(url, name) {
      this.$nextTick(() => {
        this.$refs.seePigImg.init(url, name);
      });
    },
    uploadSuccess() {
      this.getDataList(true);
      this.visible = false;
      this.$store.state.beforeUploadLoading.close();
    },
    /**
     * 新增或修改弹窗
     */
    addOrEditPopup(id) {
      this.$nextTick(() => {
        this.$refs.addOrEdit.init(id);
      });
    },
    /**
     * 删除数据
     */
    del(id) {
      if (id) {
        this.$confirm("是否删除所选择的数据?", "温馨提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$http.delete(`user-login/baseDeleteById/${id}`).then((res) => {
              if (res.data.code == 200) {
                this.getDataList();
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
              } else {
                this.$message({
                  message: res.data.msg,
                  type: "warning",
                });
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      }
    },
    /**
     * 加载列表数据数据
     * @flag {boolean} 是否设置current为1
     */
    getDataList(flag) {
      if (flag) this.queryData.current = 1;
      this.loading = true;
      this.$http
        .get(`user-login/getSelectPage`, {
          params: this.queryData,
        })
        .then((res) => {
          if (res.data.code === 200) {
            let data = res.data.data;
            if (data) {
              this.listData = data.records;
              this.total = data.total;
            }
            this.loading = false;
          }
        });
    },
    // handleScroll(event) {
    //   const bottomOfWindow =
    //     event.target.scrollHeight - event.target.scrollTop ===
    //     event.target.clientHeight;
    //   if (bottomOfWindow && !this.loading) {
    //     this.queryData.current += 1;
    //     this.getDataList();
    //   }
    // },
    getDataListSizeChange(val) {
      this.queryData.size = val;
      this.getDataList(true);
    },
    getDataListCurrentChange(val) {
      this.queryData.current = val;
      this.getDataList();
    },
  },
};
</script>
<style scoped>
.templateClass {
  padding: 10px;
}

.pc-table {
  display: block;
}

.mobile-cards {
  display: none;
}

.user-card {
  margin-bottom: 20px;
}

.card-content {
  display: flex;
  flex-direction: column;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.card-body {
  font-size: 14px;
  color: #666;
}

.el-card {
  border-radius: 8px;
  overflow: hidden;
}

.el-button {
  margin-left: 5px;
}

.loading-indicator {
  text-align: center;
  padding: 10px;
  color: #999;
}

/* 媒体查询 */
@media (max-width: 768px) {
  .pc-table {
    display: none;
  }
  .mobile-cards {
    display: block;
  }
  .input-field {
    flex: 1 !important;
  }
}
</style>