<template>
  <el-dialog
    :title="dataForm.id ? '修改' : '新增'"
    :close-on-click-modal="false"
    :visible.sync="visible"
    center
    width="800px"
  >
    <el-form
      ref="dataForm"
      :rules="dataRule"
      :model="dataForm"
      @keyup.enter.native="dataFormSubmit()"
      label-width="160px"
    >
      <el-form-item label="昵称" prop="nickName">
        <el-input
          v-model="dataForm.nickName"
          placeholder="请输入昵称"
        ></el-input>
      </el-form-item>
      <el-form-item label="姓名" prop="userName">
        <el-input
          v-model="dataForm.userName"
          placeholder="请输入姓名"
        ></el-input>
      </el-form-item>
      <el-form-item label="手机号" prop="mobile">
        <el-input
          v-model="dataForm.mobile"
          placeholder="请输入手机号"
        ></el-input>
      </el-form-item>
      <el-form-item label="密码" prop="password" v-if="!dataForm.id">
        <el-input
          v-model="dataForm.password"
          placeholder="请输入密码"
        ></el-input>
      </el-form-item>
      <el-form-item label="邮箱" prop="email">
        <el-input v-model="dataForm.email" placeholder="请输入邮箱"></el-input>
      </el-form-item>
      <el-form-item label="cover" prop="cover">
        <el-image :src="dataForm.cover" :preview-src-list="dataForm.cover ? [dataForm.cover] : []" style="width: 100px; height: 100px" />
      </el-form-item>
      <!-- <el-form-item label="logo" prop="logo">
        <el-image :src="dataForm.logo" :preview-src-list="dataForm.logo ? [dataForm.logo] : []" style="width: 100px; height: 100px" />
      </el-form-item> -->
      <el-form-item label="显示lh/fsh" prop="fsh">
        <el-radio-group v-model="dataForm.fsh">
          <el-radio :label="true">是</el-radio>
          <el-radio :label="false">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="显示绝经年龄" prop="menopausalAge">
        <el-radio-group v-model="dataForm.menopausalAge">
          <el-radio :label="true">是</el-radio>
          <el-radio :label="false">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="显示卵巢年龄" prop="isOvaryAge">
        <el-radio-group v-model="dataForm.isOvaryAge">
          <el-radio :label="true">是</el-radio>
          <el-radio :label="false">否</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="显示骨骼健康按钮" prop="boneHealth">
        <el-radio-group v-model="dataForm.boneHealth">
          <el-radio :label="true">是</el-radio>
          <el-radio :label="false">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="显示查看报告" prop="isMiniApp">
        <el-radio-group v-model="dataForm.isMiniApp">
          <el-radio :label="true">是</el-radio>
          <el-radio :label="false">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="仪器推送amh比对" prop="isCalculateAmh">
        <el-radio-group v-model="dataForm.isCalculateAmh">
          <el-radio :label="true">是</el-radio>
          <el-radio :label="false">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="编辑报告" prop="isEditReport">
        <el-radio-group v-model="dataForm.isEditReport">
          <el-radio :label="true">是</el-radio>
          <el-radio :label="false">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="类型" prop="type">
        <el-radio-group v-model="dataForm.type">
          <el-radio :label="1">普通用户</el-radio>
          <el-radio :label="2">医疗用户</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="状态" prop="status">
        <el-radio-group v-model="dataForm.status">
          <el-radio :label="1">正常</el-radio>
          <el-radio :label="0">禁用</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="报告推送类型" prop="pushType">
        <el-select v-model="dataForm.pushType" style="width: 100%">
          <el-option
            v-for="item in pushTypeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取 消</el-button>
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      dataForm: {},
      dataRule: {
        nickName: [
          { required: true, message: "该项为必填项", trigger: "blur" },
        ],
        userName: [
          { required: true, message: "该项为必填项", trigger: "blur" },
        ],
        password: [
          { required: true, message: "该项为必填项", trigger: "blur" },
        ],
        mobile: [{ required: true, message: "该项为必填项", trigger: "blur" }],
      },
      pushTypeList: [
        {
          label: "曲阜医院",
          value: 1,
        },
        {
          label: "第一健康",
          value: 2,
        },
      ],
    };
  },
  created() {},
  methods: {
    init(id) {
      this.visible = true;
      if (id) {
        this.$http.get(`user-login/baseGetById/${id}`).then((res) => {
          if (res.data.code == 200) {
            let data = res.data.data;
            delete data.password;
            if (data.pushType == 0) data.pushType = "";
            if (data) this.dataForm = data;
          }
        });
      } else {
        let type = 2;
        try {
          type = JSON.parse(localStorage.getItem("predicthealthUserInfo")).type;
        } catch (err) {}
        this.dataForm = {
          status: 1,
          type: type,
        };
      }
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          let url = "user-login/saveOrUpdateUser";
          let data = this.dataForm;
          data.roleId = "1413692745574256642";
          data.roleName = "客户";
          if (data.fsh) data.lh = true;
          else data.lh = false;
          this.$http.post(`${url}`, data).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                message: "操作成功",
                type: "success",
              });
              this.visible = false;
              this.$emit("refreshDataList");
            } else {
              this.$message({
                message: res.data.msg,
                type: "warning",
              });
            }
          });
        }
      });
    },
  },
};
</script>

<style lang='less' scoped>
</style>
